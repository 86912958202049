import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hsn_keyword: "",
  questionsArr: [],
};

export const hsnClassificationSlice = createSlice({
  name: "hsnClassification",
  initialState,
  reducers: {
    updateInput: (state, action) => {
      state.hsn_keyword = action.payload;
    },
    updateQuestionArr: (state, action) => {
      state.questionsArr = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateInput, updateQuestionArr } =
  hsnClassificationSlice.actions;

export default hsnClassificationSlice.reducer;
