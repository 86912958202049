import { Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Navbar from "./components/utilities/Navbar";
import { lazy, Suspense, useEffect, useState } from "react";
import WebPageLoader from "./components/utilities/WebPageLoader";
import "./styles/text.scss";
import "./styles/layout.scss";

const darkTheme = createTheme({
  typography: {
    button: {
      textTransform: "none",
      fontWeight: 600,
      fontFamily: "Poppins",
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#003F48",
    },
    secondary: {
      main: "#ff9449",
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        background: "#fff",
      },
      inputProps: {
        size: "small",
      },
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "var(--darkGray2)",
          color: "var(--darkGray5)",
          padding: "0.5rem 0.75rem",
          fontSize: "13px",
          fontFamily: "Poppins",
          fontWeight: 400,
          lineHeight: "20px",
        },
      },
    },
  },
});

const GRCQuestions = lazy(() => import("./components/global_readiness_check/Question"));
const HaveIGoneGlobal = lazy(() =>
  import("./components/have_gone_global/HaveIGoneGlobal")
);
const CategoryLevelInsights = lazy(() =>
  import("./components/category_level_insights/CategoryLevelInsights")
);
const CategoryLevelInsights2 = lazy(() =>
  import("./components/category_level_insights/CategoryLevelInsights2")
);
const CountryLevelInsights = lazy(() =>
  import("./components/country_level_insights/CountryLevelInsights")
);
const BrandLevelInsights = lazy(() =>
  import("./components/brand_level_insights/BrandLevelInsights")
);
const HSNClassification = lazy(() =>
  import("./components/hsn_classification/HSNClassification")
);
const TakeMeGlobal = lazy(() =>
  import("./components/utilities/TakeMeGlobalForm/TakeMeGlobalForm")
);

const AttributeSummary = lazy(() =>
  import("./components/attribute_summary/AttributeSummary")
);
const ChatBot = lazy(() => import("./components/chat_bot/ChatBot"));

const Home = lazy(() => import("./Home"));

function App() {
  const [showBottomNav, setShowBottomNav] = useState(true);
  const [subDomain, setSubDomain] = useState(true);

  useEffect(() => {
    let host = window.location.host;
    let protocol = window.location.protocol;
    let parts = host.split(".");
    let subdomain = "default";

    if (parts.length >= 3) {
      subdomain = parts[0];
    }
    setSubDomain(subdomain);
  }, []);

  let renderSubdomainRouting = () => {
    switch (subDomain) {
      case "hsn":
        return (
          <Routes>
            <Route path="/" element={<HSNClassification />} />
          </Routes>
        );
      case "insights":
        return (
          <Routes>
            <Route path="/" element={<CategoryLevelInsights2 />} />
            <Route path="/take-me-global" element={<TakeMeGlobal />} />
          </Routes>
        );
      case "strategy":
        return (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="stage/1/grc/questionnaire" element={<GRCQuestions />} />
            <Route path="stage/2/gone-global" element={<HaveIGoneGlobal />} />
            <Route
              path="stage/3/categorical-insights"
              element={<CategoryLevelInsights />}
            />
            <Route path="stage/4/country-insights" element={<CountryLevelInsights />} />
            <Route path="stage/5/brand-insights" element={<BrandLevelInsights />} />
            <Route path="stage/6/hsn-classification" element={<HSNClassification />} />
            <Route path="stage/7/attribute-summary" element={<AttributeSummary />} />
          </Routes>
        );
      default:
        return (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="stage/1/grc/questionnaire" element={<GRCQuestions />} />
            {/* <Route path="stage/2/gone-global" element={<HaveIGoneGlobal />} />
            <Route
              path="stage/3/categorical-insights"
              element={<CategoryLevelInsights />}
            />
            <Route path="stage/4/country-insights" element={<CountryLevelInsights />} />
            <Route path="stage/5/brand-insights" element={<BrandLevelInsights />} />
            <Route path="stage/6/hsn-classification" element={<HSNClassification />} />
            <Route path="stage/7/attribute-summary" element={<AttributeSummary />} />
            <Route path="/take-me-global" element={<TakeMeGlobal />} /> */}
                <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        );
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Navbar
        showBottomNav={showBottomNav}
        setShowBottomNav={setShowBottomNav}
        subDomain={subDomain}
      />

      <div
        className="App div2PDF"
        // style={{ paddingTop: `${showBottomNav ? "150px" : "75px"}` }}
      >
        <Suspense fallback={<WebPageLoader />}>
          {renderSubdomainRouting()}
          {/* <ChatBot /> */}
        </Suspense>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </ThemeProvider>
  );
}

export default App;
