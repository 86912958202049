import { configureStore } from "@reduxjs/toolkit";
// import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import sessionStorage from "redux-persist/es/storage/session";

import hsnClassificationReducer from "./slices/hsn_classification";
import attributeSummaryReducer from "./slices/attribute_summary";

const reducers = combineReducers({
  hsnClassification: hsnClassificationReducer,
  attributeSummary: attributeSummaryReducer,
});

const persistConfig = {
  key: "root",
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
