import React from "react";
import Loader from "./Loader";
import "./WebPageLoader.scss";
const WebPageLoader = () => {
  return (
    <div className="pageloader_container2">
      <div className="loaderbg">
        <div class="loadingio-spinner-wedges-fa3ou85ofp">
          <div class="ldio-3a4ujuto1tt">
            <div>
              <div>
                <div></div>
              </div>
              <div>
                <div></div>
              </div>
              <div>
                <div></div>
              </div>
              <div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="fw-sb f-xxs color-white">goGlocal Analytics</p>
          <p className="fw-m f-xxxs color-white">Loading Up</p>
        </div>
      </div>
    </div>
  );
};

export default WebPageLoader;
