import React, { useEffect, useState } from "react";
import {ReactComponent as GoglocalLogo} from "../../assets/goGlocal.svg";

import "./Navbar.scss";
import { useHorizontalScroll } from "../../hooks/useHorizontalScroll";
import { Button, IconButton } from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { useLocation } from "react-router-dom";

const Navbar = ({ showBottomNav, setShowBottomNav, subDomain }) => {
  const scrollRef = useHorizontalScroll();
  const location = useLocation();
  const [path, setPath] = useState({
    path: "",
    stage: 0,
  });
  const [isSingleDomainUsePage, setIsSingleDomainUsePage] = useState(true);

  useEffect(() => {
    let curr_stage = 1;
    const pathname = window.location.pathname;
    const arr = pathname.split("/");
    curr_stage = parseInt(arr[2]);

    if (!curr_stage) curr_stage = 0;

    setPath({
      path: pathname,
      stage: curr_stage,
    });
    console.log(curr_stage, pathname);
  }, [location.pathname]);

  console.log(path);

  useEffect(() => {
    if (subDomain === "default" || subDomain === "strategy")
      setIsSingleDomainUsePage(false);
    console.log(subDomain);
  }, [subDomain]);

  const paths = [
    {
      stage: 0,
      name: "Home",
      url: "/",
    },
    {
      stage: 1,
      name: "Global Readiness Check",
      url: "/stage/1/grc/questionnaire",
    },
    // {
    //   stage: 2,
    //   name: "Have I already Gone Global",
    //   url: "/stage/2/gone-global",
    // },
    {
      stage: 3,
      name: "Global Expansion Strategy",
      url: "https://global.goglocal.com/",
    },
    {
      stage: 4,
      name: "Demo",
      url: "https://goglocal.live/demo",
    },
    // {
    //   stage: 5,
    //   name: "Brand Level Insights",
    //   url: "/stage/5/brand-insights",
    // },
    // {
    //   stage: 6,
    //   name: "HSN Classification",
    //   url: "/stage/6/hsn-classification",
    // },
    // {
    //   stage: 7,
    //   name: "Attribute Level Summary",
    //   url: "/stage/7/attribute-summary",
    // },
  ];

  return (
    <div className="navbar">
      <div className="nav_top">
        <div className="nav_name">
          <a href="/" className="nav_logo">
            <GoglocalLogo />
          </a>
        </div>
        <div className="nav_controls">
          <div>
            <a href="https://goglocal.live/" rel="noreferrer">
              <IconButton variant="contained" size="medium">
                <HomeRoundedIcon style={{color: 'white'}}/>
              </IconButton>
            </a>
          </div>
          {!isSingleDomainUsePage && (
            <div
              className={
                showBottomNav ? "nav_bot_min_btn" : "nav_min_btn_down nav_bot_min_btn"
              }
              onClick={() => setShowBottomNav((o) => !o)}
            >
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/ffffff/chevron-up.png"
                alt="chevron-left"
              />
            </div>
          )}
        </div>
      </div>
      {!isSingleDomainUsePage && (
        <div className={`nav_bottom font-nuform-sans ${showBottomNav ? "" : "nav_bottom_hide"}`}>
          <div className="nav_mid_break"></div>
          <div id="breadcrumb" ref={scrollRef}>
            {paths.map((item, index) => {
              return (
                <li>
                  {item.stage !== path.stage ? (
                    <a
                      href={item.url}
                      className={`nav_item ${
                        item.stage <= path.stage ? "nav_item_active" : "nav_item_inactive"
                      }`}
                    >
                      {item.name}
                    </a>
                  ) : (
                    <a
                      className={`nav_item ${
                        item.stage <= path.stage ? "nav_item_active" : "nav_item_inactive"
                      }`}
                    >
                      {item.name}
                    </a>
                  )}
                </li>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
