import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selected_section: {
    section_no: 1,
    title: "",
  },
  inputs: {
    country: {
      Country: "",
      Code: "",
    },
    marketplace: "",
    leaf_node: "",
  },
};

export const attributeSummarySlice = createSlice({
  name: "attributeSummary",
  initialState,
  reducers: {
    updateAttributeNav: (state, action) => {
      state.selected_section = action.payload;
    },
    updateInputs: (state, action) => {
      state.inputs = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateAttributeNav, updateInputs } =
  attributeSummarySlice.actions;

export default attributeSummarySlice.reducer;
